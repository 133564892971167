<template>

  <div :class="boxClass">

    <div class="conflex">
      <!-- Icono -->
      <div class="columna" style="width:20px;margin-right:10px;justify-content: center;">
        <v-icon @click="showUrl">{{ icono }}</v-icon>
      </div>

      <div class="columna" style="width:80%">
        <!-- label -->
        <div
          v-if="label"
          :class="labelTop ? 'labelTop' : 'label'"
          :style="styleLabel">
            {{ label }}
        </div>

        <!-- campo -->
        <input
          v-bind="$attrs"
          v-model="displayValue"
          :class="labelTop ? 'inputTop' : 'input'"
          :style="styleInput"
          @focus="isInputActive = true"
        />

        <span></span>
      </div>
    </div>
  </div>
</template>



<script>

  export default {
    props: {
      value: { type: [String], default: "" },
      labelSide: { type: Boolean, default: false },
      label: { type: [String, Number], default: "" },
      icono: { type: String, default: "mdi-web" },

      styleBox: { type: String, default: "" },
      styleInput: { type: String, default: "" },
      styleLabel: { type: String, default: "" },
    },

    data() {
      return {
        isInputActive: false,
      };
    },


    methods: {

      showUrl() {
        if (!this.value) return;

        let url= this.value.substr(0,4).toLowerCase()=='http'? this.value : ('http://' + this.value);
        window.open(url, "_blank");
      }

    },


    computed: {
      displayValue: {
        get: function () {
          return this.value.toString();
        },

        set: function (val) {
          this.$emit("input", val);
        },
      },

      labelTop: function () {
        if (this.labelSide) return false;
        return true;
      },

      boxClass() {
        let clase = "";
        //  <div :class="labelTop ? 'boxTop' : 'box'" :style="styleBox">
        clase = this.labelTop ? "boxTop" : "box";
        clase += this.isInputActive ? " boxFocused" : "";
        return clase;
      },
    },
  };
</script>




<style scoped>
.label {
  /* por defecto el label ocupa siempre su contenido */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 6px 2px 18px;

  font-size: 0.85rem;
  border-right: 1px solid white;
  border-radius: 18px 0 0 18px;
  background-color: rgb(176, 190, 197, 0.35);
  color: steelblue;
  user-select: none;
}

.labelTop {
  font-size: 0.85rem;
  color: steelblue;
  /* padding: 5px 12px 0 0; */
  padding-top: 5px;
  line-height: 10px;
  user-select: none;
}

.inputTop {
  flex: 1 1 100%;
  /* poner por defecto a los numéricos alineación izquierda? */
  /* text-align: right; */
  padding: 0px 12px 0 0;
  /* color: red; */
}
.styleBox {
  outline: 0;
  box-shadow: 0 0 1px blue;
}

.input {
  flex: 1 1 100%;
  /* text-align: right; */
  padding: 0px 12px 0 8px;
}
.box {
  flex: 1 1 auto;
  display: flex;
  height: 2rem;
  font-size: 1rem;
  border-radius: 18px;
  background-color: rgb(240, 240, 240);
  margin: 0 0px 4px 0;
}

.boxTop {
  height: 37.73px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  border-radius: 18px;
  background-color: rgb(240, 240, 240);
  margin: 0 0px 4px 0;
  padding: 0px 0px 0 18px;
}
.boxFocused {
  box-shadow: 0px 0px 3px steelblue;
}

.box .fieldError {
  /* background-color: lightblue; */
  /* outline:none; */
  /* border-radius: 0 ; */
  /* border: 1px solid steelblue; */
  /* outline: 2px solid red; */
  /* outline-style:inset; */

  color: white;
  background-color: lightcoral;
}

input:focus {
  outline: none;
}
input:invalid + span:before {
  /* https://www.kirupa.com/html5/emoji.htm */
  /* https://emojipedia.org/ */
  /* https://www.w3schools.com/charsets/ref_emoji.asp */
  /* https://stackoverflow.com/questions/32413731/color-for-unicode-emoji */
  /* content:'\01F354';  */
  content: "✖";
  color: red;
  margin-left: -15px;
}
/* input:valid+span:after {
    content: '✓';
    padding-left: 5px;
} */

.validity {
  color: red;
}
</style>
